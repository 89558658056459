<template>
  <v-app>
    <tev-navbar backTo="/login" title="" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12>
          <v-form ref="form" class="form-sm">
            <v-container>
              <template v-if="passwordChanged">
                <v-layout row wrap>
                  <v-flex xs12 sm12 text-xs-center align-center justify-content-center>
                    <CheckMark class="mb-5 mt-4"/>
                    <template v-if="newAccount">
                      <h3 class="mb-4">Your account has been created</h3>
                      <p class="text-muted mb-5">You can sign into your new account with your email and password.</p>
                    </template>
                    <template v-else>
                      <h3 class="mb-4">Your password has been changed</h3>
                      <p class="text-muted mb-5">You can sign into your account with your new password.</p>
                    </template>
                    <v-btn depressed large color="primary" to="/login">LogIn</v-btn>
                  </v-flex>
                </v-layout>
              </template>
              <template v-else>
                <tev-pagetitle title="Activate your account" subtitle="Enter a password for your new account and then confirm your password by entering it a second time." v-if="newAccount"/>
                <tev-pagetitle title="Reset your password" subtitle="Enter your new password for your account and then confirm your password by entering it a second time." v-else/>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-text-field xs12 v-model="newPassword" :rules="rules.password" label="New Password" @click:append="toggleNew" :append-icon="newIcon" :type="newType" required box :autofocus="$isDesktop()"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12>
                    <v-text-field xs12 v-model="confirmPassword" :rules="rules.confirmPassword" label="Confirm Password" @click:append="toggleConfirm" :append-icon="confirmIcon" :type="confirmType" required box :autofocus="$isDesktop()"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 text-xs-center>
                    <v-btn color="primary" large depressed @click="resetPassword" :loading="loading" v-bind:class="{'blue--text': loading}" :disabled="loading">
                      <template v-if="newAccount">Activate</template>
                      <template v-else>Reset</template>
                    </v-btn>
                    <v-btn flat large color="primary" to="/login">Cancel</v-btn>
                  </v-flex>
                </v-layout>
              </template>
            </v-container>
          </v-form>
          <v-snackbar v-model="snackbar" :bottom="true" :left="true">
            {{ snackbarText }}
            <v-btn color="red" flat @click="snackbar = false">
              Close
            </v-btn>
          </v-snackbar>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import Validator from '../libs/validator'
import CheckMark from '../components/CheckMark'

export default {
  data() {
    const _this = this;
    const validator = new Validator();
    return {
      loading: false,

      newAccount: false,

      key: null,
      newPassword: null,
      confirmPassword: null,

      passwordChanged: false,

      newType: 'password',
      newIcon: 'remove_red_eye',
      confirmType: 'password',
      confirmIcon: 'remove_red_eye',

      snackbar: false,
      snackbarText: '',

      rules: {
        password: [
          validator.rules.required("You must complete the required fields."),
          validator.rules.minLength(8, "Your password must contain no less than 8 characters."),
          validator.rules.maxLength(100, "Your password should not contain more than 100 characters."),
        ],
        confirmPassword: [
          validator.rules.matches(_this, "newPassword", "The passwords you entered do not match.")
        ]
      }
    }
  },
  components: {
    CheckMark
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  methods: {
    alertError(message) {
      this.$events.$emit('showSnackbar', {
        message: message,
        color: 'red'
      });
    },
    getType(el) {
      return el == 'text'? 'password' : 'text'
    },
    getIcon(el) {
      return el == 'text'? 'more_horiz' : 'remove_red_eye'
    },
    toggleNew() {
      this.newType = this.getType(this.newType)
      this.newIcon = this.getIcon(this.newType)
    },
    toggleConfirm() {
      this.confirmType = this.getType(this.confirmType)
      this.confirmIcon = this.getIcon(this.confirmType)
    },
    resetPassword() {
      const _this = this;
      if(!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      this.$http.post('/manager/reset-password/finish', {
        key: this.key,
        newPassword: this.newPassword
      })
      .then(() => {
          _this.loading = false;
          _this.passwordChanged = true
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e)
          _this.loading = false;
          _this.alertError(e.error || 'An error occurred while processing your request.')
        })
    }
  },
  mounted() {
    this.key = this.$route.params.key;
    if(!this.key) {
      this.$router.push('/login')
    }

    if(this.$route.name == '/activate/:key') {
      this.newAccount = true
    }
  }
}
</script>
<style>
</style>