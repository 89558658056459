var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { backTo: "/login", title: "" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-form",
                    { ref: "form", staticClass: "form-sm" },
                    [
                      _c(
                        "v-container",
                        [
                          _vm.passwordChanged
                            ? [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          sm12: "",
                                          "text-xs-center": "",
                                          "align-center": "",
                                          "justify-content-center": "",
                                        },
                                      },
                                      [
                                        _c("CheckMark", {
                                          staticClass: "mb-5 mt-4",
                                        }),
                                        _vm.newAccount
                                          ? [
                                              _c(
                                                "h3",
                                                { staticClass: "mb-4" },
                                                [
                                                  _vm._v(
                                                    "Your account has been created"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-muted mb-5",
                                                },
                                                [
                                                  _vm._v(
                                                    "You can sign into your new account with your email and password."
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : [
                                              _c(
                                                "h3",
                                                { staticClass: "mb-4" },
                                                [
                                                  _vm._v(
                                                    "Your password has been changed"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-muted mb-5",
                                                },
                                                [
                                                  _vm._v(
                                                    "You can sign into your account with your new password."
                                                  ),
                                                ]
                                              ),
                                            ],
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              depressed: "",
                                              large: "",
                                              color: "primary",
                                              to: "/login",
                                            },
                                          },
                                          [_vm._v("LogIn")]
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _vm.newAccount
                                  ? _c("tev-pagetitle", {
                                      attrs: {
                                        title: "Activate your account",
                                        subtitle:
                                          "Enter a password for your new account and then confirm your password by entering it a second time.",
                                      },
                                    })
                                  : _c("tev-pagetitle", {
                                      attrs: {
                                        title: "Reset your password",
                                        subtitle:
                                          "Enter your new password for your account and then confirm your password by entering it a second time.",
                                      },
                                    }),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            xs12: "",
                                            rules: _vm.rules.password,
                                            label: "New Password",
                                            "append-icon": _vm.newIcon,
                                            type: _vm.newType,
                                            required: "",
                                            box: "",
                                            autofocus: _vm.$isDesktop(),
                                          },
                                          on: { "click:append": _vm.toggleNew },
                                          model: {
                                            value: _vm.newPassword,
                                            callback: function ($$v) {
                                              _vm.newPassword = $$v
                                            },
                                            expression: "newPassword",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            xs12: "",
                                            rules: _vm.rules.confirmPassword,
                                            label: "Confirm Password",
                                            "append-icon": _vm.confirmIcon,
                                            type: _vm.confirmType,
                                            required: "",
                                            box: "",
                                            autofocus: _vm.$isDesktop(),
                                          },
                                          on: {
                                            "click:append": _vm.toggleConfirm,
                                          },
                                          model: {
                                            value: _vm.confirmPassword,
                                            callback: function ($$v) {
                                              _vm.confirmPassword = $$v
                                            },
                                            expression: "confirmPassword",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          sm12: "",
                                          "text-xs-center": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            class: {
                                              "blue--text": _vm.loading,
                                            },
                                            attrs: {
                                              color: "primary",
                                              large: "",
                                              depressed: "",
                                              loading: _vm.loading,
                                              disabled: _vm.loading,
                                            },
                                            on: { click: _vm.resetPassword },
                                          },
                                          [
                                            _vm.newAccount
                                              ? [_vm._v("Activate")]
                                              : [_vm._v("Reset")],
                                          ],
                                          2
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              flat: "",
                                              large: "",
                                              color: "primary",
                                              to: "/login",
                                            },
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-snackbar",
                    {
                      attrs: { bottom: true, left: true },
                      model: {
                        value: _vm.snackbar,
                        callback: function ($$v) {
                          _vm.snackbar = $$v
                        },
                        expression: "snackbar",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.snackbarText) +
                          "\n          "
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.snackbar = false
                            },
                          },
                        },
                        [_vm._v("\n            Close\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }